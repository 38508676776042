import PinoLogger from './pino.ts';
import ConsoleLogger from './console.ts';

export interface Logger {
  info(msg: string): void;
  error(msg: string): void;
}

const logger: Logger = getLogger();

function getLogger(): Logger {
  if (isBrowser()) {
    return new ConsoleLogger();
  }

  return process.env?.LOGGER === 'pino'
    ? new PinoLogger()
    : new ConsoleLogger();
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export default logger;
