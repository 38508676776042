import { isBrowser, type Logger } from './index';

class ConsoleLogger implements Logger {
  info(msg: string): void {
    if (isBrowser() && window.environment === 'production') return;

    printArgs(`INFO: ${msg}`);
  }

  error(msg: string): void {
    if (isBrowser() && window.environment === 'production') return;

    printArgs(`ERROR: ${msg}`);
  }
}

function printArgs(...args: any): void {
  if (args?.length === 0) return;
  args.forEach((arg: any) => {
    console.log(arg);
  });
}

export default ConsoleLogger;
